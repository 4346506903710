
<template>
    <div>
      <b-overlay rounded="sm" no-fade>
        <b-card>

<div v-if="data.tasksList.length>0">

    
    <div class="todo-header">
      <div class="header-cell w-30">Task Name</div>
      <div class="header-cell w-15">Client Name</div>

      <div class="header-cell w-20">Project Name</div>

      <div class="header-cell w-15" style="white-space: nowrap;">Due Date</div>
      <div class="header-cell w-5">Type</div>
      <div class="header-cell w-5">Scope</div>
      <div class="header-cell w-10">Priority</div>
    </div>

    <div v-for="(task, index) in data.tasksList" v-if="!task.isCompleted" :key="index">
      <div class="task-content">
        <div class="todo-cell w-3 d-flex justify-content-center">
          <i v-if="task.sub_tasks.length > 0" :class="['fa-solid', 'fa-caret-' + (task.isExpanded ? 'down' : 'right'), 'mr-1 text-dark']" style="font-size: 1rem; cursor:pointer" @click="toggleExpand(task)"></i>
          <i v-else :class="['fa-solid', 'fa-caret-right', 'mr-1']" style="font-size: 1rem; cursor:pointer; visibility: hidden"></i>
          <div class="custom-checkbox">
            <span class="checkbox-icon"  :class="{ checked: task.isCompleted }" @click="updateTaskIsCompleted(task)"></span>
          </div>
        </div>



        <div class="todo-cell w-27 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
          <div class="task-name">
            <div class="w-100">{{ task.taskName }}</div>
          </div>
        </div>

        <div class="todo-cell w-15 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
          <div class="task-name">
            <div class="w-100">{{ task.clientName ? task.clientName : null}}</div>
          </div>
        </div>

        
        <div class="todo-cell w-20 hover-background" style="cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
          <div class="task-name">
            <div class="w-100">{{ task.project ? task.project.name : null }}</div>
          </div>
        </div>
     


    
     
      

        <div class="todo-cell w-15 hover-background">
          <b-form-datepicker
            :id="'due-date-' + task.id.toString()"
            placeholder="Add due date"
            v-model="task.dueDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"
            @input="updateTaskDueDate(task.id, task.dueDate)"
            locale="en"
            label-help=""
            :start-weekday="1"
            :hide-header="true"
            style="border:none; background-color: transparent;"
          />
        </div>

                   
        <div class="todo-cell w-5" style="cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
          <div class="task-name">
            <div class="w-100">

            <b-badge
                    pill
                    :variant="`light-warning`"
                    class="text-capitalize"
                    v-if="task.task_type"
                  >
                  {{ task.task_type ? task.task_type : 'Primary' }}
                  </b-badge>

                     
              <b-badge
                    pill
                    :variant="`light-primary`"
                    class="text-capitalize"
                    v-else
                  >
                  {{ task.task_type ? task.task_type : 'Primary' }}
                  </b-badge>
            
                </div>
          </div>
        </div>
            
        <div class="todo-cell w-5" style="cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
          <div class="task-name">
            <div class="w-100">
            
            
              <b-badge
                    pill
                    :variant="`light-danger`"
                    class="text-capitalize"
                    v-if="task.isPersonal"
                  >
                  {{ task.isPersonal ? 'Personal' : 'General' }}
                  </b-badge>

                     
              <b-badge
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                    v-else
                  >
                  {{ task.isPersonal ? 'Personal' : 'General' }}
                  </b-badge>
            
            
            </div>
          </div>
        </div>

        <div class="todo-cell w-10 hover-background">
          <button
            :style="{
              backgroundColor: getSectionBgColor(task.priority),
              color: getSectionColor(task.priority),
            }"
            style=" border: none; padding: 5px; cursor: pointer; width:100%"
            :id="'section-controls-' + task.id.toString()"
          >
            {{ task.priority || 'N/A' }}
          </button>
          <b-popover :target="'section-controls-' + task.id.toString()" :show.sync="popoverShow[task.id]" triggers="focus" variant="primary" placement="left">
            <div class="d-flex flex-column">
              <button @click="updateTaskPriority(task, 'Critical')" class="mb-25" style="padding:5px 25px;  border:none" :style="{ backgroundColor: getSectionBgColor('Critical'), color: getSectionColor('Critical') }">Critical</button>
              <button @click="updateTaskPriority(task, 'High')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('High'), color: getSectionColor('High') }">High</button>
              <button @click="updateTaskPriority(task, 'Medium')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Medium'), color: getSectionColor('Medium') }">Medium</button>
              <button @click="updateTaskPriority(task, 'Low')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Low'), color: getSectionColor('Low') }">Low</button>
              <button @click="updateTaskPriority(task, 'Not a Priority')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Not a Priority'), color: getSectionColor('Not a Priority') }">Not a Priority</button>
            </div>
          </b-popover>
        </div>
      </div>

      <!-- SUB TASKSSSSSSSSS -->
      <div v-for="(subtask, index) in task.sub_tasks" :key="index">
        <div v-if="task.isExpanded" class="subtask-content">
          <div class="todo-cell w-5  d-flex justify-content-end">
            <i class="fa-solid fa-caret-right" style="font-size: 1rem;  visibility: hidden; margin-right:2.2rem"></i>
            <div class="custom-checkbox">
              <span class="checkbox-icon" :class="{ checked: subtask.isCompleted }" @click="updateTaskIsCompleted(subtask)"></span>
            </div>
          </div>

          <div class="todo-cell w-45 hover-background" style="border-left:none;  cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(subtask)">
            <div class="task-name">
              <div>{{ subtask.taskName }}</div>
            </div>
          </div>

          <div class="todo-cell w-15  hover-background">
            <div class="w-100">
              <span v-if="subtask.userTitle != 'Assign This Task'">
                <b-dropdown :text="subtask.userTitle" variant="link" block no-caret>
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <b-avatar v-if="subtask.userTitle != 'Assign This Task'" :text="getAvatarText(subtask.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                      <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                      <span class="text-dark" style="margin-left: auto; margin-right: auto;">{{ subtask.userTitle }}</span>
                    </div>
                  </template>

                  <b-dropdown-item @click="updateTaskAssigneUser(subtask, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                    {{ user.fullName }}
                  </b-dropdown-item>

                  <b-dropdown-divider />
                  <b-dropdown-item variant="danger" @click="updateTaskAssigneUser(subtask, { fullName: 'Assign This Task', id: null })">Remove from project</b-dropdown-item>
                </b-dropdown>
              </span>

              <span v-else class="text-info">
                <b-dropdown text="Assign This Task" variant="link" block no-caret>
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <b-avatar v-if="subtask.userTitle != 'Assign This Task'" :text="getAvatarText(subtask.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                      <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                      <span class="text-dark" style="margin-left: auto; margin-right: auto;">{{ subtask.userTitle }}</span>
                    </div>
                  </template>

                  <b-dropdown-item @click="updateTaskAssigneUser(subtask, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                    {{ user.fullName }}
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </div>

          <div class="todo-cell w-10  hover-background">
            <div class="w-100">
              <span>
                <div class="d-flex align-items-center">
                  <b-avatar :text="getAvatarText(name)" v-for="(name, index) in subtask.additionalUserTitles" :key="index" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                </div>
              </span>
            </div>
          </div>

          <div class="todo-cell w-15  p-0 m-0 hover-background">
            <b-form-datepicker
              :id="'due-date-' + subtask.id.toString()"
              placeholder="Add due date"
              v-model="subtask.dueDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }"
              @input="updateTaskDueDate(subtask.id, subtask.dueDate)"
              locale="en"
              label-help=""
              :start-weekday="1"
              :hide-header="true"
              style="border:none; background-color: transparent;"
            />
          </div>

          <div class="todo-cell w-10 hover-background">
            <button
              :style="{
                backgroundColor: getSectionBgColor(subtask.priority),
                color: getSectionColor(subtask.priority),
              }"
              style=" border: none; padding: 5px; cursor: pointer; width:100%"
              :id="'date-subtask-controls-' + subtask.id.toString()"
            >
              {{ subtask.priority || 'N/A' }}
            </button>
            <b-popover :target="'date-subtask-controls-' + subtask.id.toString()" :show.sync="popoverShow[subtask.id]" triggers="focus" variant="primary" placement="left">
              <div class="d-flex flex-column">
                <button @click="updateTaskPriority(subtask, 'Critical')" class="mb-25" style="padding:5px 25px;  border:none" :style="{ backgroundColor: getSectionBgColor('Critical'), color: getSectionColor('Critical') }">Critical</button>
                <button @click="updateTaskPriority(subtask, 'High')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('High'), color: getSectionColor('High') }">High</button>
                <button @click="updateTaskPriority(subtask, 'Medium')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Medium'), color: getSectionColor('Medium') }">Medium</button>
                <button @click="updateTaskPriority(subtask, 'Low')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Low'), color: getSectionColor('Low') }">Low</button>
                <button @click="updateTaskPriority(subtask, 'Not a Priority')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Not a Priority'), color: getSectionColor('Not a Priority') }">Not a Priority</button>
              </div>
            </b-popover>
          </div>
        </div>
      </div>
      <!-- SUB TASK FINISHEDDD -->
    </div>

    <!-- <div class="task-content add-cell" @click="handleTaskAdd()">
      <div class="w-3"></div>

      <div class="w-97 d-flex justify-content-start">
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-plus mr-1"></i>

          <span>Add new task...</span>
        </div>
      </div>
    </div> -->


  </div>

  <div v-else>
    <div class="no-results" :class="{ show: data.tasks.length }">
      <h5>No Items Found</h5>
    </div>

  </div>
</b-card>
  <todo-task-handler-sidebar
        @handle-task-click="handleTaskClick"
        @update-task-is-completed="updateTaskIsCompleted"
        @update-task-due-date="updateTaskDueDate"
        v-model="isTaskHandlerSidebarActive"
        :modal-task-data.sync="tempTaskData"
        :task-filters="taskFilters"
        :blank-temp-task-data="blankTempTaskData"
      />
    </b-overlay>
  </div>
</template>


<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { VBPopover } from 'bootstrap-vue';
import homeStoreModule from './homeStoreModule.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormDatepicker } from 'bootstrap-vue';
import { formatDate, getAvatarText } from './helpers';

export default {
  components: {
    VuePerfectScrollbar,
    TodoTaskHandlerSidebar,
    ToastificationContent,
    quillEditor,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
  },

  directives: {
    'b-popover': VBPopover,
  },
  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';

    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: '#description-toolbar',
      },
      placeholder: 'What is this task about?',
    };

    const blankTempTaskData = {
      id: null,
      assignedUser: null,
      additionalAssigneeUser: null,
      dueDate: null,
      description: null,
      projectId: null,
    };

    const tempTaskData = JSON.parse(JSON.stringify(blankTempTaskData));

    return {
      popoverShow: {},
      selectedUserId: null,
      selectedUserTitle: null,
      tempMemberRole: null,
      users: [],
      editedDescription: null,
      descriptionOption,
      isNameEditing: false,
      isDescriptionEditing: false,
      loading: true,
      activeTab: 2,

      isTaskHandlerSidebarActive: false,
      blankTempTaskData,
      tempTaskData,

      taskFilters: [
        { title: 'Critical', textColor: '#000000', bgColor: '#f06a6a' },
        { title: 'High', textColor: '#000000', bgColor: '#ec8d71' },
        { title: 'Medium', textColor: '#000000', bgColor: '#f1bd6c' },
        { title: 'Low', textColor: '#000000', bgColor: '#b3df97' },
        { title: 'Not a Priority', textColor: '#FFFFFF', bgColor: '#4573d2' },
      ],
    };
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
},

  mounted() {
    this.getUsers();
  },
  watch: {
    isTaskHandlerSidebarActive: function(newVal, oldVal) {
      if (!newVal) {
      }
    },
  },

  methods: {
    formatDate,
    getAvatarText,

    updateTaskDueDate(taskId, dueDate) {
      this.loading = true;

      store
        .dispatch('home/updateTaskDueDate', {
          id: taskId,
          dueDate: dueDate,
        })
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Due date has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskPriority(task, priority) {
      this.popoverShow[task.id] = false;
      task.priority = priority;
      this.loading = true;

      store
        .dispatch('home/updateTaskPriority', {
          id: task.id,
          priority: priority,
        })
        .then((res) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Priority has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskAssigneUser(task, assignedUser) {
      task.userTitle = assignedUser.fullName;
      task.assignedUser = assignedUser.id;
      this.loading = true;

      store
        .dispatch('home/updateTaskAssigneUser', {
          id: task.id,
          assignedUser: assignedUser.id,
        })
        .then((res) => {
          this.loading = false;

          const successText = task.isCompleted ? '✔️ Task has been marked as completed.' : '⭕ Task has been marked as not completed.';

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: successText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskIsCompleted(task) {
      if(this.$Can('dashboard_complete_task')){
        task.isCompleted = !task.isCompleted;
      this.loading = true;
      console.log(task);
      store
        .dispatch('home/updateTaskIsCompleted', {
          id: task.id,
          isCompleted: task.isCompleted,
        })
        .then((res) => {
          this.loading = false;

          const successText = task.isCompleted ? '✔️ Task has been marked as completed.' : '⭕ Task has been marked as not completed.';

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: successText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      }
      
    },








    getSectionColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    toggleExpand(task) {
      task.isExpanded = !task.isExpanded;
    },

    handleTaskClick(val) {
      if(false){
        this.tempTaskData = val;
      this.isTaskHandlerSidebarActive = true;
      }
   
    },

    handleTaskAdd() {
      if(false){
        this.tempTaskData = JSON.parse(JSON.stringify(this.blankTempTaskData));
      this.isTaskHandlerSidebarActive = true;

      }
  
    },

    getUsers() {
      store
        .dispatch('home/fetchUsersProject', [])
        .then((res) => {
          this.users = res.data;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #73c0d2;
  color:white;

}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-30 {
  width: 30%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-32 {
  width: 32%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-27{
  width: 27%;
}
.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
